// ACCORDEON TOGGLE FUNCTIONALITY
document.querySelectorAll(".accordeon-header").forEach((item) => {
  item.addEventListener("click", (event) => {
    event.target.closest(".module-accordeon").classList.toggle("active");
  });
});

// INITIALIZE ALL SLIDER ELEMENTS
let sliderElements = document.querySelectorAll(".slider-click");
sliderElements.forEach(function (sliderElement) {
  // Initialize Slider
  let slides = sliderElement.getElementsByClassName("slider-element-click");
  for (i = 0; i < slides.length; i++) {
    slides[i].classList.add("hidden");
  }
  slides[0].classList.remove("hidden");

  //Set Button Actions
  sliderElement.querySelector(".prev").addEventListener("click", function () {
    changeSlide(-1, sliderElement);
  });
  sliderElement.querySelector(".next").addEventListener("click", function () {
    changeSlide(1, sliderElement);
  });
});

function changeSlide(n, sliderElement) {
  // Get current and calculate "nextSlide"
  let nextSlide;
  let slides = sliderElement.getElementsByClassName("slider-element-click");
  for (i = 0; i < slides.length; i++) {
    if (!slides[i].classList.contains("hidden")) {
      nextSlide = i + n;
    }
  }

  // Reset the slideIndex if it exceeds the total number of slides
  if (nextSlide > slides.length - 1) {
    nextSlide = 0;
  } else if (nextSlide < 0) {
    nextSlide = slides.length - 1;
  }

  // Set all images to hidden, except for "nextSlide"
  for (i = 0; i < slides.length; i++) {
    slides[i].classList.add("hidden");
  }
  slides[nextSlide].classList.remove("hidden");
}
